import React from "react";

function ContactPhoneIcon() {
  return (
    <div>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.22897 8.71349C6.26255 7.35001 7.38129 6.25098 8.75264 6.25098C10.1451 6.25098 11.278 7.38384 11.278 8.77631C11.278 10.1774 10.126 11.3297 8.70063 11.3003C9.49538 13.6411 11.3507 15.4964 13.6915 16.2911C13.6622 14.8685 14.8113 13.7138 16.2155 13.7138C17.608 13.7138 18.7408 14.8467 18.7408 16.2391C18.7408 17.6619 17.5644 18.7645 16.2468 18.7645C10.4777 18.7645 6.07202 13.8725 6.22897 8.71349Z"
          fill="#CB8A0A"
        />
        <path
          d="M3.66316 3.66315C8.54699 -1.22068 16.4523 -1.22142 21.3368 3.66315C26.2207 8.54692 26.2215 16.4523 21.3368 21.3368C16.453 26.2207 8.54768 26.2214 3.66316 21.3368C-1.22066 16.4531 -1.22145 8.54766 3.66316 3.66315ZM12.5 22.9442C18.259 22.9442 22.9442 18.2589 22.9442 12.5C22.9442 6.74102 18.259 2.0558 12.5 2.0558C6.74109 2.0558 2.05582 6.74107 2.05582 12.5C2.05582 18.259 6.74104 22.9442 12.5 22.9442Z"
          fill="#CB8A0A"
        />
      </svg>
    </div>
  );
}

export default ContactPhoneIcon;
