import React from "react";
import { useNavigate } from "react-router-dom";
import CartCard from "../components/Cart/CartCard";
import NavBar from "../components/NavBar/NavBar";
import useCartItems from "../queries/useCartItems";
import { AppContext } from "../constants/AppContext";

const CartPage = () => {
  const { user } = React.useContext(AppContext);

  const { data, status, error } = useCartItems();
  let navigate = useNavigate();

  if (status === "loading") {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (status === "error") {
    return <div> {error.message}</div>;
  }
  const cartItems = data?.data || [];
  console.log("first", cartItems);

  function checkOut() {
    navigate("/selectaddress");
  }

  return (
    <div style={{ backgroundColor: "#F3F3F3" }} className="h-screen">
      <div className="pb-20">
        <NavBar />
      </div>
      <div className="lg:max-w-7xl mx-auto px-4 flex flex-wrap items-center pl-7">
        {cartItems.map((item, index) => {
          return <CartCard key={index} item={item} />;
        })}
      </div>
      {user ? (
        <div className="flex justify-center py-20">
          <button
            className="bg-primary text-lg font-extrabold text-white py-3 text-center w-96 cursor-pointer rounded-2xl "
            onClick={checkOut}
          >
            Checkout
          </button>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CartPage;
