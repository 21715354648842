/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginForm from "../components/Popup/LoginForm";
import OTPForm from "../components/Popup/OtpForm";
import { AppContext } from "../constants/AppContext";

function LoginPage({ closeModal }) {
  const { confirmation, setConfirmation } = React.useContext(AppContext);

  const backtoLogin = () => {
    setConfirmation(null);
  };

  return (
    <div>
      <div className="w-80 min-login-height py-4 px-6 bg-white rounded-2xl flex flex-col  justify-end items-center">
        {confirmation ? (
          <OTPForm backtoLogin={backtoLogin} closeModal={closeModal} />
        ) : (
          <LoginForm closeModal={closeModal} />
        )}
      </div>
    </div>
  );
}

export default LoginPage;

export function useURLQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
