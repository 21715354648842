import { getAuth, signInWithPhoneNumber } from "firebase/auth";

const firbaseLogin = ({ phoneNumber }) => {
  console.log("phoneNumber+", phoneNumber);
  const auth = getAuth();
  auth.languageCode = "en";
  const appVerifier = window.recaptchaVerifier;
  return signInWithPhoneNumber(auth, phoneNumber, appVerifier);
};

const firbaseConfirmation = ({ verificationCode }) => {
  return window.confirmationResult.confirm(verificationCode);
};

export { firbaseLogin, firbaseConfirmation };
