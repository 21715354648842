/* eslint-disable no-unused-vars */

import React from "react";

const SliderCard = ({ picture_url, setImage }) => {
  return (
    <div
      style={{
        backgroundColor: "#EFEFEF",
        backgroundImage: `url(${picture_url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
      onClick={() => setImage(picture_url)}
      className="h-20 w-20 m-4"
    ></div>
  );
};

export default SliderCard;
