import React from "react";

function InstagramIcon() {
  return (
    <div>
      {" "}
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.8712 0H10.1288C4.54375 0 0 4.54375 0 10.1288V23.8712C0 29.4562 4.54375 34 10.1288 34H23.8712C29.4562 34 34 29.4562 34 23.8712V10.1288C33.9999 4.54375 29.4562 0 23.8712 0ZM30.5796 23.8712C30.5796 27.5761 27.5761 30.5796 23.8712 30.5796H10.1288C6.42388 30.5796 3.4204 27.5761 3.4204 23.8712V10.1288C3.4204 6.42381 6.42388 3.4204 10.1288 3.4204H23.8712C27.5761 3.4204 30.5796 6.42381 30.5796 10.1288V23.8712Z"
          fill="url(#paint0_linear_391_672)"
        />
        <path
          d="M16.9998 8.20605C12.151 8.20605 8.20618 12.1509 8.20618 16.9996C8.20618 21.8483 12.151 25.7932 16.9998 25.7932C21.8486 25.7932 25.7934 21.8484 25.7934 16.9996C25.7934 12.1508 21.8486 8.20605 16.9998 8.20605ZM16.9998 22.3729C14.0322 22.3729 11.6266 19.9672 11.6266 16.9997C11.6266 14.0321 14.0323 11.6265 16.9998 11.6265C19.9674 11.6265 22.373 14.0321 22.373 16.9997C22.373 19.9672 19.9673 22.3729 16.9998 22.3729Z"
          fill="url(#paint1_linear_391_672)"
        />
        <path
          d="M25.8109 10.3793C26.9746 10.3793 27.918 9.4359 27.918 8.27217C27.918 7.10843 26.9746 6.16504 25.8109 6.16504C24.6471 6.16504 23.7037 7.10843 23.7037 8.27217C23.7037 9.4359 24.6471 10.3793 25.8109 10.3793Z"
          fill="url(#paint2_linear_391_672)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_391_672"
            x1="17"
            y1="33.901"
            x2="17"
            y2="0.264084"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E09B3D" />
            <stop offset="0.3" stop-color="#C74C4D" />
            <stop offset="0.6" stop-color="#C21975" />
            <stop offset="1" stop-color="#7024C4" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_391_672"
            x1="16.9998"
            y1="33.9007"
            x2="16.9998"
            y2="0.26375"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E09B3D" />
            <stop offset="0.3" stop-color="#C74C4D" />
            <stop offset="0.6" stop-color="#C21975" />
            <stop offset="1" stop-color="#7024C4" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_391_672"
            x1="25.8109"
            y1="33.9008"
            x2="25.8109"
            y2="0.263843"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#E09B3D" />
            <stop offset="0.3" stop-color="#C74C4D" />
            <stop offset="0.6" stop-color="#C21975" />
            <stop offset="1" stop-color="#7024C4" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}

export default InstagramIcon;
