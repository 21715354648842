import React from "react";
// import Search from "../components/HomeComponents/Search";
import NavBar from "../components/NavBar/NavBar";
import Banner from "../components/Banner/Banner";
import SearchCategory from "../components/HomeComponents/SearchCategory";
import useDashboard from "../queries/useDashboard";
import MobileView from "../components/Mobile/MobileView";

const HomePage = () => {
  const { data, status, error } = useDashboard();
  if (status === "loading") {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const { categories } = data.data;

  return (
    <div>
      <NavBar />
      <div className="content">
        <Banner />
        {/* <div className="container mx-auto">
            <Search />
          </div> */}
        <div className="">
          <SearchCategory categories={categories} />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
