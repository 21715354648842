import React from "react";

function DeleteIcon() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C15.5223 0 20 4.47768 20 10C20 15.5223 15.5223 20 10 20C4.47768 20 0 15.5223 0 10C0 4.47768 4.47768 0 10 0ZM5.16518 13.3705C5.09821 13.4375 5.0625 13.5268 5.0625 13.6205C5.0625 13.7143 5.09821 13.808 5.16518 13.8705L6.12946 14.8393C6.20089 14.9107 6.29018 14.942 6.37946 14.942C6.46875 14.942 6.5625 14.9062 6.62946 14.8393L10 11.4643L13.3661 14.8438C13.433 14.9152 13.5268 14.9464 13.6161 14.9464C13.7054 14.9464 13.7991 14.9107 13.8661 14.8438L14.8304 13.875C14.8973 13.808 14.933 13.7188 14.933 13.625C14.933 13.5313 14.8973 13.4375 14.8304 13.375L11.4509 9.98214L14.8393 6.63393C14.9777 6.49554 14.9777 6.26786 14.8393 6.12946L13.875 5.16071C13.808 5.09375 13.7187 5.05804 13.625 5.05804C13.5312 5.05804 13.442 5.09375 13.375 5.16071L9.99553 8.49554L6.61607 5.16071C6.54911 5.09375 6.45982 5.05804 6.36607 5.05804C6.27232 5.05804 6.18304 5.09375 6.11607 5.16071L5.15178 6.12946C5.01339 6.26786 5.01339 6.49554 5.15178 6.63393L8.54018 9.98214L5.16518 13.3705Z"
          fill="#FF4040"
        />
      </svg>
    </>
  );
}

export default DeleteIcon;
