import React from "react";

function BackIcon() {
  return (
    <div>
      {" "}
      <svg
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="mr-3 mt-1"
      >
        <path
          d="M8.33333 0.752244C8.66437 0.750356 8.98849 0.845193 9.26435 1.02466C9.54022 1.20413 9.75534 1.46011 9.88228 1.75995C10.0092 2.05979 10.0422 2.38991 9.97713 2.70823C9.91202 3.02654 9.75171 3.31863 9.51667 3.54725L4.01667 8.92479L9.51667 14.3023C9.78971 14.615 9.93239 15.0172 9.91619 15.4286C9.89998 15.8399 9.7261 16.2302 9.42927 16.5213C9.13245 16.8124 8.73455 16.9829 8.31509 16.9988C7.89563 17.0147 7.4855 16.8747 7.16667 16.607L0.499999 10.0689C0.189582 9.7627 0.0153459 9.34843 0.0153458 8.91661C0.0153458 8.4848 0.189582 8.07053 0.499999 7.76428L7.16667 1.22625C7.4771 0.924287 7.8961 0.754048 8.33333 0.752244Z"
          fill="#241BD0"
        />
        <path
          d="M1.66667 7.29028L18.3333 7.29028C18.7754 7.29028 19.1993 7.46248 19.5118 7.76901C19.8244 8.07554 20 8.49129 20 8.92478C20 9.35828 19.8244 9.77403 19.5118 10.0806C19.1993 10.3871 18.7754 10.5593 18.3333 10.5593L1.66667 10.5593C1.22464 10.5593 0.800716 10.3871 0.488155 10.0806C0.175596 9.77403 1.24948e-06 9.35828 1.21216e-06 8.92479C1.17484e-06 8.49129 0.175595 8.07554 0.488155 7.76901C0.800716 7.46248 1.22464 7.29028 1.66667 7.29028Z"
          fill="#241BD0"
        />
      </svg>
    </div>
  );
}

export default BackIcon;
