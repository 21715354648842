import React from "react";

function ThreeLineIcon() {
  return (
    <div>
      <svg
        width={21}
        height={14}
        viewBox="0 0 21 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 1C0 0.447715 0.447715 0 1 0H20C20.5523 0 21 0.447715 21 1C21 1.55228 20.5523 2 20 2H1C0.447715 2 0 1.55228 0 1Z"
          fill="#A6A6A6"
        />
        <path
          d="M0 7C0 6.44772 0.447715 6 1 6H20C20.5523 6 21 6.44772 21 7C21 7.55228 20.5523 8 20 8H1C0.447715 8 0 7.55228 0 7Z"
          fill="#A6A6A6"
        />
        <path
          d="M0 13C0 12.4477 0.447715 12 1 12H20C20.5523 12 21 12.4477 21 13C21 13.5523 20.5523 14 20 14H1C0.447715 14 0 13.5523 0 13Z"
          fill="#A6A6A6"
        />
      </svg>
    </div>
  );
}

export default ThreeLineIcon;
