/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Modal from "react-modal";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import VerifiedScreen from "./VerifiedScreen";
import CloseIcon from "../Svg/CloseIcon";
import BackIcon from "../Svg/BackIcon";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

//*********************************** */

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
Modal.setAppElement("#root");

const RegistrationScreen = ({ closeModal }) => {
  const [mobileNumber, setMobileNumber] = useState();
  const [verificationCode, setVerificationCode] = useState();
  const [otpSent, setOtpSent] = useState(true);

  function handleMobileNumber(e) {
    setMobileNumber(e.target.value);
  }

  function handleVerificationCode(e) {
    setVerificationCode(e.target.value);
  }

  function openVerifiedScreen() {
    return (
      <>
        <VerifiedScreen closeModal={closeModal} />
      </>
    );
  }

  // ******************************************************************
  // Firebase Code

  function onSignInSubmit(event) {
    event.preventDefault();

    const phoneNumber = "+91" + mobileNumber;
    const appVerifier = window.recaptchaVerifier;
    hiddenCaptcha();

    const auth = getAuth();
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setOtpSent(true);
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
      });
  }

  function onOtpSubmit() {
    const code = verificationCode;
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;

        localStorage.setItem("token", user.accessToken);
        alert("User Signed In");
        openVerifiedScreen();
        closeModal();
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
      });
  }

  function hiddenCaptcha() {
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          onSignInSubmit();
        },
      },
      auth
    );
  }

  // ******************************************************************

  // const handleKeyDown = (e) => {
  //   // e.preventDefault();
  //   if (e.keyCode === 13) {
  //     onSignInSubmit();
  //   }
  // };

  const handleKeyDown = (e) => {
    if (e.key === 13 || e.key === "Enter") {
      otpSent ? onOtpSubmit(e) : onSignInSubmit(e);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const backtoLogin = () => {
    const { search } = location;
    const parsed = queryString.parse(search);
    delete parsed.registration;
    delete parsed.login;
    const stringified = queryString.stringify(parsed);
    navigate(location.pathname + "?" + stringified + "&login=true");
  };

  return (
    <div>
      <div id="sign-in-button"></div>
      {otpSent ? (
        <div
          style={{
            width: "384px",
            height: "550px",
          }}
          className="min-w-96 border border-black py-4  bg-white rounded-2xl flex flex-col justify-center items-center"
        >
          <div className="flex justify-between  w-full h-16">
            <h1 className="px-6 font-extrabold text-lg flex  pt-4">
              <span onClick={backtoLogin} className="cursor-pointer">
                <BackIcon />
              </span>
              Registration
            </h1>
            <div
              className="flex px-4 justify-end cursor-pointer "
              onClick={closeModal}
            >
              <CloseIcon />
            </div>
          </div>

          <div className=" px-16">
            <div className="mt-10 mb-8 w-80 ">
              <h2 className="text-xl font-black text-primary text-center mb-4">
                One Time Password
              </h2>
              <h2 className="font-light text-lg text-center">
                Please enter 6 digit OTP sent on mobile number{" "}
                <span className="font-extrabold">{`+91${mobileNumber}`} </span>
              </h2>
            </div>
            <div className="w-80">
              <h2 className="font-extrabold pb-1">Enter OTP</h2>
              <div
                style={{ backgroundColor: "#DFDFDF" }}
                className="rounded-xl flex"
              >
                <input
                  style={{
                    letterSpacing: "1rem",
                  }}
                  type="number"
                  placeholder="- - - - - - "
                  className="outline-none font-semibold p-4 pl-6 rounded-xl text-3xl bg-transparent w-full"
                  onChange={(e) => {
                    handleVerificationCode(e);
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>

              <button
                className="bg-primary font-bold text-xl w-full text-white p-4 rounded-xl my-6"
                onClick={onOtpSubmit}
              >
                Continue
              </button>
              <h2 className="my-4 text-base text-center">
                Resend in
                <span className="text-primary font-extrabold">30 Seconds</span>
              </h2>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "384px",
            height: "550px",
          }}
          className=" border border-black py-4  bg-white rounded-2xl flex flex-col items-center justify-center"
        >
          <div className="flex justify-between  w-full h-16">
            <h1 className="px-6 font-extrabold text-lg flex  pt-4">
              <span onClick={backtoLogin} className="cursor-pointer">
                <BackIcon />
              </span>
              Registration
            </h1>
            <div
              className="flex px-4 justify-end cursor-pointer "
              onClick={closeModal}
            >
              <CloseIcon />
            </div>
          </div>

          <div className=" px-16">
            <div className="mt-10 mb-8 w-80 ">
              <h2 className="text-xl font-black text-primary text-center mb-4">
                Verify Mobile Number
              </h2>
              <h2 className="font-light text-lg text-center">
                Please enter your mobile number to register on PnD Pro app
              </h2>
            </div>
            <div className="w-80">
              <h2 className="font-bold pb-1">Mobile Number</h2>
              <div
                style={{ backgroundColor: "#DFDFDF" }}
                className="rounded-xl flex"
              >
                <p className="font-bold text-lg my-auto pl-4">+91</p>
                <input
                  type="number"
                  placeholder="Enter mobile number"
                  className="outline-none font-semibold p-4 rounded-xl bg-transparent w-full"
                  onChange={(e) => {
                    handleMobileNumber(e);
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              </div>

              <button
                className="bg-primary font-bold text-lg w-full text-white p-4 rounded-xl my-6"
                onClick={onSignInSubmit}
              >
                Verify Mobile Number
              </button>
              <h2 className="my-4 text-base text-center">
                By tapping verify number, an SMS may be sent. Message &#38; data
                rates may apply
              </h2>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationScreen;
