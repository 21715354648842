import React from "react";
import useFavoriteMutation from "../../queries/useFavoriteMutation";

function HeartIcon({ isFavorite = 0, item_code }) {
  const { mutate: mutation } = useFavoriteMutation();
  const createFavorite = () => {
    console.log("isFavorite", isFavorite);
    if (isFavorite === 0) {
      mutation({
        item_code: item_code,
        operation: 1,
      });
    } else {
      mutation({
        item_code: item_code,
        operation: 0,
      });
    }
  };

  return (
    <div onClick={createFavorite} className="cursor-pointer">
      <svg
        width={30}
        height={30}
        viewBox="0 0 40 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.9872 24.7341C13.8115 24.7352 13.6373 24.6995 13.4746 24.6292C13.3119 24.5588 13.164 24.4551 13.0392 24.324L2.6642 13.3228C1.3628 11.9303 0.632813 10.0502 0.632812 8.09091C0.632813 6.13167 1.3628 4.25155 2.6642 2.85898C3.97578 1.47394 5.75244 0.696167 7.60468 0.696167C9.45691 0.696167 11.2336 1.47394 12.5452 2.85898L13.9872 4.38614L15.4293 2.85898C16.7409 1.47394 18.5176 0.696167 20.3698 0.696167C22.222 0.696167 23.9987 1.47394 25.3103 2.85898C26.6117 4.25155 27.3417 6.13167 27.3417 8.09091C27.3417 10.0502 26.6117 11.9303 25.3103 13.3228L14.9353 24.324C14.8105 24.4551 14.6625 24.5588 14.4999 24.6292C14.3372 24.6995 14.163 24.7352 13.9872 24.7341Z"
          fill={isFavorite === 1 ? "red" : "#CCCCCC"}
        />
      </svg>
    </div>
  );
}

export default HeartIcon;
