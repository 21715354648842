import React from "react";
import FavouritesCard from "../components/Favourites/FavouritesCard";
import NavBar from "../components/NavBar/NavBar";
import useFavorites from "../queries/useFavorites";

const FavouritesPage = () => {
  const { data, status, error } = useFavorites();
  if (status === "loading") {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (status === "error") {
    return <div> {error.message}</div>;
  }
  const favourites = data?.data || [];

  return (
    <div style={{ backgroundColor: "#F3F3F3" }} className="h-screen">
      <div className="pb-20">
        <NavBar />
      </div>
      <div className="lg:max-w-7xl mx-auto px-4 flex flex-wrap items-center">
        {favourites.map((item, index) => {
          return <FavouritesCard key={index} item={item} />;
        })}
      </div>
    </div>
  );
};

export default FavouritesPage;
