import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { getHeaders } from "./helper";
import useProfile from "./useProfile";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const setData = async (props, token) => {
  const config = getHeaders();

  if (!token) {
    return;
  } else {
    config.headers.ab_token = token;
  }

  const { data } = await axios.post(
    baseURL + "customerprofile/updateFavorite",
    props,
    config
  );

  return data;
};

export default function useFavoriteMutation() {
  const { data } = useProfile();
  const queryCache = useQueryClient();
  const location = useLocation();

  let navigate = useNavigate();

  return useMutation((props) => setData(props, data?.data?.token), {
    onSuccess: (res) => {
      if (res) {
        toast.success("Success", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        queryCache.invalidateQueries();
      } else {
        navigate(location.pathname + location.search + "&login=true");
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });
}
