import React from "react";

const MobileView = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen text-xl font-bold">
      Pease Download Our Mobile App
      <div className="w-screen p-4">
        <a
          href="https://play.google.com/store/apps/details?id=com.allsoft.pndtextile"
          className="text-white bg-primary h-14  flex justify-center items-center rounded-xl w-full my-8 font-extrabold text-lg cursor-pointer"
        >
          Download From Play Store
        </a>
        <a
          href="https://apps.apple.com/in/app/pnd-textiles/id1534609517"
          className="text-white bg-primary h-14 px-6 flex justify-center items-center rounded-xl w-full my-8 font-extrabold text-lg cursor-pointer"
        >
          Download From App Store
        </a>
      </div>
    </div>
  );
};

export default MobileView;
