/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useURLQuery } from "../../Pages/LoginPage";
import SliderCard from "./SliderCard";
import HeartIcon from "../Svg/HeartIcon";

const ImageSlider = ({ product_details, product_pictures = [] }) => {
  let { item_picture = null, isFavorite } = product_details;
  const query = useURLQuery();
  const item_code = query.get("item_code");
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    if (item_picture === null) {
      item_picture =
        "https://pndtextiles.s3.ap-south-1.amazonaws.com/place-holders/pnd-place-holder.png";
    }
    item_picture = item_picture + "?v=" + new Date().getTime();
    setImage(item_picture);
    return () => {
      setImage(null);
    };
  }, [item_picture]);

  return (
    <div className="">
      <div
        style={{
          backgroundColor: "#EFEFEF",
          width: 575,
          height: 345,
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        className="h-96 rounded-xl flex flex-col  justify-between items-end  p-2 mx-4 border-2"
      >
        <div className="cursor-pointer">
          <HeartIcon isFavorite={isFavorite} item_code={item_code} />
        </div>
      </div>
      <div
        style={{
          width: "600px",
        }}
        className="overflow-scroll"
      >
        <div style={{ width: "2300px" }} className="overflow-hidden my-2 flex ">
          {product_pictures.map((item, index) => {
            return (
              <SliderCard
                key={index}
                picture_url={item.picture_url}
                setImage={setImage}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ImageSlider);

// <ReactImageMagnify
// {...{
//   smallImage: {
//     alt: "Wristwatch by Ted Baker London",
//     isFluidWidth: true,
//     src: image,
//   },
//   largeImage: {
//     src: image,
//     width: 700,
//     height: 700,
//   },
// }}
// >

// </ReactImageMagnify>
