import React from "react";

function NavNotificationIcon({ notification_count = 0 }) {
  return (
    <div className="relative">
      {notification_count === 0 ? null : (
        <span className="absolute -top-3 text-xs left-4 font-bold">
          {notification_count}
        </span>
      )}
      <svg
        width={19}
        height={21}
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.3852 13.8672L16.4953 11.9669V7.28414C16.5205 5.54526 15.9156 3.856 14.7925 2.52828C13.6693 1.20056 12.1037 0.324044 10.3847 0.0605782C9.38707 -0.0708034 8.37289 0.0120868 7.40982 0.303718C6.44676 0.595349 5.55696 1.08901 4.79981 1.75178C4.04265 2.41454 3.43554 3.23115 3.01899 4.14714C2.60244 5.06312 2.38603 6.05741 2.38419 7.06365V11.9669L0.494306 13.8672C0.256921 14.1086 0.0959424 14.4145 0.0314939 14.7469C-0.0329547 15.0792 0.0019865 15.4232 0.131949 15.7358C0.261912 16.0483 0.48113 16.3157 0.762198 16.5044C1.04327 16.693 1.37371 16.7947 1.71223 16.7966H5.24002V17.1535C5.28906 18.2194 5.75864 19.2224 6.54585 19.9427C7.33307 20.663 8.37372 21.0419 9.43977 20.9963C10.5058 21.0419 11.5465 20.663 12.3337 19.9427C13.1209 19.2224 13.5905 18.2194 13.6395 17.1535V16.7966H17.1673C17.5058 16.7947 17.8363 16.693 18.1173 16.5044C18.3984 16.3157 18.6176 16.0483 18.7476 15.7358C18.8775 15.4232 18.9125 15.0792 18.848 14.7469C18.7836 14.4145 18.6226 14.1086 18.3852 13.8672ZM11.5396 17.1535C11.4814 17.6586 11.2306 18.1216 10.8394 18.4463C10.4482 18.771 9.94687 18.9322 9.43977 18.8964C8.93266 18.9322 8.4313 18.771 8.04012 18.4463C7.64895 18.1216 7.39812 17.6586 7.33989 17.1535V16.7966H11.5396V17.1535ZM2.62568 14.6967L3.8646 13.4578C4.06112 13.2624 4.21703 13.0301 4.32334 12.7742C4.42966 12.5183 4.48428 12.2439 4.48407 11.9669V7.06365C4.48464 6.35537 4.63671 5.6554 4.93007 5.01072C5.22344 4.36605 5.6513 3.79158 6.18496 3.32588C6.71142 2.84888 7.33525 2.49197 8.01326 2.27986C8.69127 2.06776 9.40728 2.00552 10.1117 2.09746C11.326 2.29461 12.4281 2.92377 13.2152 3.8691C14.0024 4.81443 14.4215 6.01229 14.3955 7.24214V11.9669C14.3939 12.2432 14.4468 12.5172 14.5513 12.773C14.6558 13.0289 14.8098 13.2616 15.0044 13.4578L16.2539 14.6967H2.62568Z"
          fill="#241BD0"
        />
      </svg>
    </div>
  );
}

export default NavNotificationIcon;
