/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */

import React from "react";
import { useURLQuery } from "../../App";
import useCartMutation from "../../queries/useCartMutation";
import { toast } from "react-toastify";
import Loader from "../Svg/Loader";
import PlusIcon from "../Svg/PlusIcon";
import MinusIcon from "../Svg/MinusIcon";
import useCartItems from "../../queries/useCartItems";

const PricingDetails = ({ product_details }) => {
  const query = useURLQuery();
  const item_code = query.get("item_code");
  const {
    item_name,
    ecomm_list_price,
    ecomm_unit = "Yard",
    cart_quantity,
    ecomm_selling_price,
  } = product_details;

  const { mutate: mutation, isLoading } = useCartMutation();

  const [state, setState] = React.useState({
    cart_quantity: cart_quantity,
    ecomm_unit: ecomm_unit,
  });

  const ToastifyWarn = () => {
    toast.warn("Oops! Your Cart Quantity is 0", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const TostifyMessage = () => {
    toast.warn(
      "Price per Roll will be updated at the time of Order acceptance",
      {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const UnitChange = () => {
    TostifyMessage();
    setState({
      ...state,
      ecomm_unit: ecomm_list_price.target.value,
    });
  };

  const reduceCardQuantity = () => {
    if (state.cart_quantity > 1) {
      setState({
        ...state,
        cart_quantity: state.cart_quantity - 1,
      });
    }
  };

  const increaseCardQuantity = () => {
    setState({
      ...state,
      cart_quantity: state.cart_quantity + 1,
    });
  };

  console.log("CART", cart_quantity);

  const handleAddToCart = () => {
    mutation({
      item_code: item_code,
      item_unit: state.ecomm_unit,
      quantity: state.cart_quantity,
      cart_price: ecomm_selling_price,
      list_price: ecomm_list_price,
      device_name: "web",
    });
  };

  const cartQuantityCheck = () => {
    state.cart_quantity <= 0 ? ToastifyWarn() : handleAddToCart();
  };

  return (
    <>
      <div className="w-full flex flex-col justify-between pb-10 px-10 select-none ">
        <div>
          <h1 className="text-2xl font-medium pb-4">{item_name}</h1>
          <div className="h-14 mb-6 flex">
            <div
              className="border-2 w-20 rounded-xl flex justify-center items-center cursor-pointer"
              onClick={reduceCardQuantity}
            >
              <MinusIcon />
            </div>
            <div
              style={{ backgroundColor: "#F3F3F3" }}
              className="mx-4   rounded-xl text-xl w-full font-extrabold flex justify-center items-center"
            >
              {state.cart_quantity}
            </div>
            <div
              className="border-2 w-20  rounded-xl flex justify-center items-center cursor-pointer"
              onClick={increaseCardQuantity}
            >
              <PlusIcon />
            </div>
          </div>
          <div className="w-full border-2 px-4 mb-6 font-extrabold text-lg rounded-xl flex justify-between h-14 items-center">
            <p>Price</p>
            <p style={{ color: "#069843" }} className="">
              {ecomm_list_price}
            </p>
          </div>
          <div className="w-full border-2 px-4 font-extrabold text-lg rounded-xl flex justify-between h-14 items-center">
            <p>Unit</p>
            <select
              // value={state.ecomm_unit}
              onChange={UnitChange}
            >
              <option value="Yards">Yards</option>
              <option value="Rolls">Rolls</option>
            </select>
          </div>
        </div>

        <button
          onClick={cartQuantityCheck}
          className="text-white bg-primary h-14 flex justify-center items-center rounded-xl w-full font-extrabold text-xl cursor-pointer"
        >
          {isLoading ? (
            <span className="flex justify-center items-center">
              <Loader />
              <h3>Loading</h3>
            </span>
          ) : (
            <span className="w-full h-full  text-white bg-primary flex justify-center items-center rounded-xl font-extrabold text-xl cursor-pointer hover:bg-primary">
              {cart_quantity > 0 ? "Update Cart " : "Add To Cart"}
            </span>
          )}
        </button>
      </div>
    </>
  );
};

export default PricingDetails;

//  background-color: rgba(0, 0, 0, 0.3);
