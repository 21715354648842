/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import queryString from "query-string";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import CloseIcon from "../Svg/CloseIcon";

import LoginImage from "../Svg/LoginImage.jpeg";
import COUNTRY_DIAL_CODES from "../../constants/constants";
import Button from "../Core/Button";
import { useMutation } from "react-query";
import { firbaseLogin } from "../../queries/firebaseApi";
import { AppContext } from "../../constants/AppContext";

function LoginForm({ closeModal }) {
  const [mobileNumber, setMobileNumber] = useState();
  const { setConfirmation } = React.useContext(AppContext);
  const [countryCode, setCountryCode] = useState("+66");

  let phoneNumber = countryCode + mobileNumber;

  function handleMobileNumber(e) {
    setMobileNumber(e.target.value);
  }

  const { mutate: login, isLoading: loginLoading } = useMutation(firbaseLogin, {
    onSuccess: (data) => {
      window.confirmationResult = data;
      window.mobileNumber = phoneNumber;
      setConfirmation(data);
    },
    onError: (error) => {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  function hiddenCaptcha() {
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          console.log({ response });
          // login({ phoneNumber });
        },
      },
      auth
    );
  }

  const onSubmitForm = (e) => {
    e.preventDefault();
    login({ phoneNumber });
  };

  React.useEffect(() => {
    hiddenCaptcha();
  }, []);

  const location = useLocation();
  let navigate = useNavigate();

  function registrationScreen() {
    const { search } = location;
    const parsed = queryString.parse(search);
    parsed.registration = "true";
    parsed.login = "false";
    const stringified = queryString.stringify(parsed);
    navigate(location.pathname + "?" + stringified);
  }

  return (
    <form onSubmit={onSubmitForm}>
      <div>
        <div
          className="flex px-4 justify-end cursor-pointer "
          onClick={closeModal}
        >
          <CloseIcon />
        </div>
        <h1 className="text-primary font-extrabold text-2xl text-center ">
          Login
        </h1>
      </div>

      <div className=" flex justify-center items-center">
        <img src={LoginImage} alt="Login Banner" width={300} />
      </div>

      <div style={{ backgroundColor: "#DFDFDF" }} className="rounded-xl flex">
        <select
          style={{ backgroundColor: "#DFDFDF" }}
          className="font-bold text-lg my-auto pl-1 w-16"
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
        >
          {COUNTRY_DIAL_CODES.map((country, index) => (
            <option key={index} value={country.dial_code}>
              {country.dial_code}
            </option>
          ))}
        </select>
        <input
          type="number"
          placeholder="Enter mobile number"
          className="outline-none font-semibold p-3 h-12 rounded-xl text-lg bg-transparent w-full"
          onChange={handleMobileNumber}
          required
          autoFocus
        />
      </div>
      <div id="sign-in-button"></div>
      <Button type="submit" loading={loginLoading}>
        Login
      </Button>
    </form>
  );
}

export default LoginForm;

export function useURLQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
