import React from "react";
import AwesomeSlider from "react-awesome-slider";

const Banner = () => {
  return (
    <div className="banner">
      <AwesomeSlider style={{ height: "25rem", zIndex: 0 }} bullets={false}>
        <div className="bg-">
          <img
            src="/assets/images/fabric2.jpg"
            alt=""
            className="w-full h-full"
          />
        </div>
        <div className="bg-">
          <img
            src="/assets/images/fabric2.jpg"
            alt=""
            className="w-full h-full"
          />
        </div>
      </AwesomeSlider>
    </div>
  );
};

export default Banner;
