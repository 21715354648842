/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import useAddresses from "../../queries/useAddresses";
import useAddressMutation from "../../queries/useAddressMutation";
import BackIcon from "../Svg/BackIcon";
import CloseIcon from "../Svg/CloseIcon";

const NewAddress = ({ closeModal }) => {
  const { mutate: mutation } = useAddressMutation();
  const { data } = useAddresses();
  const [person, setPerson] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setState] = React.useState("");
  const [pin, setPin] = React.useState("");

  const handlePerson = (e) => {
    setPerson(e.target.value);
  };

  const handleCompany = (e) => {
    setCompany(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleState = (e) => {
    setState(e.target.value);
  };
  const handleCountry = (e) => {
    setCountry(e.target.value);
  };

  const handlePin = (e) => {
    setPin(e.target.value);
  };

  const addNewAddress = () => {
    mutation({
      address_pin: pin,
      address_city: city,
      address_state: state,
      address_country: country,
      address_text: address,
      organization_name: company,
      contact_person: person,
    });
  };

  const navigate = useNavigate();
  return (
    <div
      style={{ width: "850px", height: "600px" }}
      className="bg-white rounded-2xl p-4"
    >
      <div
        className="flex px-4 justify-end cursor-pointer"
        onClick={() => closeModal()}
      >
        <CloseIcon />
      </div>
      <h1
        className="px-6 font-extrabold text-xl flex cursor-pointer"
        onClick={() => closeModal()}
      >
        <span>
          <BackIcon />
        </span>
        Add New Address
      </h1>
      <div className=" my-6 px-20">
        <div className="flex space-x-8">
          <div className="w-1/2">
            <h1 className="py-2 pl-1">Contact Person</h1>
            <input
              style={{ background: "#F3F3F3" }}
              type="text"
              className="outline-none rounded-xl p-3 w-full h-14 font-extrabold px-4"
              placeholder="Contact Person"
              onChange={handlePerson}
            />
          </div>
          <div className="w-1/2">
            <h1 className="py-2 pl-1">Company Name</h1>
            <input
              style={{ background: "#F3F3F3" }}
              type="text"
              className="outline-none rounded-xl p-3 w-full h-14 font-extrabold px-4"
              placeholder="Company Name"
              onChange={handleCompany}
            />
          </div>
        </div>
        <div className="flex space-x-8">
          <div className="w-full">
            <h1 className="py-2 pl-1">Address</h1>
            <input
              style={{ background: "#F3F3F3" }}
              type="text"
              className="outline-none rounded-xl p-3 w-full h-14 font-extrabold px-4"
              placeholder="Enter Address"
              onChange={handleAddress}
            />
          </div>
        </div>
        <div className="flex space-x-8">
          <div className="w-1/2">
            <h1 className="py-2 pl-1">State</h1>
            <div className=" border-2 rounded-xl  px-4">
              <select
                type="text"
                className="outline-none  w-full h-14 text-xl font-extrabold cursor-pointer"
                placeholder="Contact Person"
                onChange={handleState}
              >
                <option>Karnataka</option>
                <option selected>Maharastra</option>
                <option>Chhattisgarh</option>
                <option>Kerala</option>
              </select>
            </div>
          </div>
          <div className="w-1/2">
            <h1 className="py-2 pl-1">City</h1>
            <div className=" border-2 rounded-xl  px-4">
              <select
                type="text"
                className="outline-none  w-full h-14 text-xl font-extrabold cursor-pointer"
                placeholder="Comapny Name"
                onChange={handleCity}
              >
                <option>Delhi</option>
                <option selected>Mumbai</option>
                <option>Banglore</option>
                <option>Chennai</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex space-x-8">
          <div className="w-1/2">
            <h1 className="py-2 pl-1">Country</h1>
            <div className=" border-2 rounded-xl  px-4">
              <select
                type="text"
                className="outline-none  w-full h-14 text-xl font-extrabold cursor-pointer"
                placeholder="Select Country"
                onChange={handleCountry}
              >
                <option>Thailand</option>
                <option selected>India</option>
                <option>Sri Lanka</option>
                <option>Nepal</option>
              </select>
            </div>
          </div>
          <div className="w-1/2">
            <h1 className="py-2 pl-1">Pincode</h1>
            <input
              style={{ background: "#F3F3F3" }}
              type="text"
              className="outline-none rounded-xl p-3 w-full h-14 font-extrabold px-4"
              placeholder="Enter Zipcode"
              onChange={handlePin}
            />
          </div>
        </div>

        <div className="flex justify-center mt-5">
          <button
            className="bg-primary text-lg font-extrabold text-white py-3 text-center w-96 rounded-2xl m-4 "
            onClick={() => closeModal()}
          >
            Save Address
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewAddress;
