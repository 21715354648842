/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";
import { useQueryClient } from "react-query";
import useProfile, { getProfileData } from "../queries/useProfile";
// import messaging from '@react-native-firebase/messaging'
// import { Platform } from 'react-native'
// import Toast from 'react-native-simple-toast'
// import { useQueryClient } from "react-query";
// import i18next from 'i18next'
// import { initReactI18next, useTranslation } from 'react-i18next'
// import { getLocales } from 'react-native-localize'
// import translationEN from './translations/en/translations.json'
// import translationTH from './translations/th/translations.json'
// import { createNavigatorFactory, useNavigation } from '@react-navigation/native'
// import AsyncStorage from '@react-native-async-storage/async-storage'
// import { useNetInfo } from '@react-native-community/netinfo'

export const AppContext = React.createContext(() => {});

export const AppProvider = ({ children }) => {
  const [initializing, setInitializing] = React.useState(true);
  const [user, setUser] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [idToken, setIdToken] = useState("");
  const queryClient = useQueryClient();
  // const { t, i18n } = useTranslation()

  // const handleFirstConnectivityChange = () => {}

  // const subscribeToNotifications = async () => {
  //   await messaging().subscribeToTopic('pndtextiles')

  //   if (Platform.OS === 'ios') {
  //     await messaging().subscribeToTopic('pndtextiles-ios')
  //   } else {
  //     await messaging().subscribeToTopic('pndtextiles-android')
  //   }
  // }

  // const setLanguage = async () => {
  //   try {
  //     const lng = await AsyncStorage.getItem('@language')
  //     if (lng) {
  //       i18n.changeLanguage(lng)
  //     } else {
  //       await AsyncStorage.setItem('@language', 'en')
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }

  // useEffect(() => {
  //   setLanguage()
  // }, [i18n.language])

  // const requestUserPermission = async () => {
  //   const authStatus = await messaging().requestPermission()
  //   const enabled =
  //     authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
  //     authStatus === messaging.AuthorizationStatus.PROVISIONAL

  //   if (enabled) {
  //     console.log('Authorization status:', authStatus)

  //     subscribeToNotifications()
  //   }
  // }

  // const logout = async () => {
  //   if (auth().currentUser) {
  //     await auth().signOut()
  //   }
  //   const _phone = auth().currentUser?.phoneNumber

  //   await setUser(null)
  //   await setIdToken('')
  //   // await queryCache.invalidateQueries('profile')
  //   await queryClient.invalidateQueries('favorites')
  //   await queryClient.invalidateQueries('cartItems')
  //   messaging().unsubscribeFromTopic(
  //     `${_phone}-${Platform.OS === 'ios' ? 'ios' : 'android'}`,
  //   )
  // }

  // useEffect(() => {
  //   // if (idToken.length > 0) {
  //   //   queryCache.invalidateQueries('profile')
  //   // }
  // }, [idToken, user])

  // useEffect(() => {
  //   messaging().getToken()
  //   messaging().onMessage((payload) => {
  //     if (payload?.notification?.title) {
  //       Toast.show(payload?.notification?.title)
  //     }

  //     if (payload?.data?.type === 'logout') {
  //       logout()
  //     }
  //   })
  //   if (Platform.OS === 'ios') {
  //     requestUserPermission()
  //   } else {
  //     subscribeToNotifications()
  //   }
  //   return () => {
  //     // cleanup
  //   }
  // }, [])

  const onAuthStateChanged = async (_user) => {
    // await setUser(_user)
    if (_user) {
      setUser(_user);
      setIdToken(_user?.accessToken);
      await queryClient.invalidateQueries("profile");
      setInitializing(false);
      // await setIdToken((await _user?.getIdToken(true)) || "");
      // messaging().subscribeToTopic(_user?.phoneNumber?.replace('+', ''))
      // if (Platform.OS === 'ios') {  {
      //   messaging().subscribeToTopic(
      //     _user.phoneNumber?.replace('+', '') + '-ios',
      //   )
      // } else {
      //   messaging().subscribeToTopic(
      //     _user.phoneNumber?.replace('+', '') + '-android',
      //   )
      // }
    } else {
      setInitializing(false);
    }
  };

  useEffect(() => {
    // messaging().onMessage((payload) => {
    //   console.log('Message received. ', payload)
    // })
    // messaging().setBackgroundMessageHandler(async (remoteMessage) => {
    //   if (remoteMessage?.data?.type === 'logout') {
    //     logout()
    //   }
    // })
    // messaging().onNotificationOpenedApp((remoteMessage) => {
    //   if (remoteMessage?.data?.type === 'logout') {
    //     logout()
    //   }
    // })

    // messaging().
    const auth = getAuth;
    const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        initializing,
        idToken,
        // logout,
        confirmation,
        setConfirmation,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
