/* eslint-disable no-unused-vars */
import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage";
import CategoryPage from "./Pages/CategoryPage";
import ProductPage from "./Pages/ProductPage";
import ProductDetailsPage from "./Pages/ProductDetailsPage";
import FavouritesPage from "./Pages/FavouritesPage";
import CartPage from "./Pages/CartPage";
import SelectAddress from "./Pages/SelectAddress";
import OrdersPage from "./Pages/OrdersPage";
import ContactPage from "./Pages/ContactPage";
import HomeLayout from "./layouts/HomeLayout";
import LoginScreen from "./Pages/LoginPage";
import LogOutPage from "./Pages/LogOutPage";

const HomeRoute = () => (
  <HomeLayout>
    <HomePage />
  </HomeLayout>
);

const CategoryRoute = () => (
  <HomeLayout>
    <CategoryPage />
  </HomeLayout>
);

const ProductRoute = () => (
  <HomeLayout>
    <ProductPage />
  </HomeLayout>
);

const ProductDetailsRoute = () => (
  <HomeLayout>
    <ProductDetailsPage />
  </HomeLayout>
);

const FavRoute = () => (
  <HomeLayout>
    <FavouritesPage />
  </HomeLayout>
);

const CartRoute = () => (
  <HomeLayout>
    <CartPage />
  </HomeLayout>
);

const AddressRoute = () => (
  <HomeLayout>
    <SelectAddress />
  </HomeLayout>
);

const OrderRoute = () => (
  <HomeLayout>
    <OrdersPage />
  </HomeLayout>
);

const ContactRoute = () => (
  <HomeLayout>
    <ContactPage />
  </HomeLayout>
);

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeRoute />} />
      <Route path="/category" element={<CategoryRoute />} />
      <Route path="/product" element={<ProductRoute />} />
      <Route path="/productdetail" element={<ProductDetailsRoute />} />
      <Route path="/favourites" element={<FavRoute />} />
      <Route path="/cart" element={<CartRoute />} />
      <Route path="/selectaddress" element={<AddressRoute />} />
      <Route path="/myorders" element={<OrderRoute />} />
      <Route path="/contactus" element={<ContactRoute />} />
      <Route path="/logout" element={<LogOutPage />} />
    </Routes>
  );
};

export default App;

export function useURLQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
