import { useQuery } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { getHeaders } from "./helper";
import useProfile from "./useProfile";

const getData = async (token) => {
  const config = getHeaders();

  if (!token) {
    return {
      data: [],
      status: true,
    };
  } else {
    config.headers.ab_token = token;
  }

  const { data } = await axios.post(
    baseURL + "customerprofile/favoriteList",
    {},
    config
  );

  return data;
};

export default function useFavorites() {
  const { data } = useProfile();

  return useQuery("favorites", () => getData(data?.data?.token));
}
