import { useQuery } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { getHeaders } from "./helper";

const getData = async () => {
  const config = getHeaders();

  const { data } = await axios.post(baseURL + "masters/dashboard", {}, config);

  return data;
};

export default function useDashboard() {
  return useQuery("dashboard", getData);
}
