import { useQuery } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import useProfile from "./useProfile";
import { getHeaders } from "./helper";

const getData = async (token) => {
  const config = getHeaders();

  if (!token) {
    return;
  } else {
    config.headers.ab_token = token;
  }

  const { data } = await axios.post(
    baseURL + "customerprofile/savedAddressList",
    null,
    config
  );
  return data;
};

export default function useAddresses() {
  const { data } = useProfile();
  return useQuery("addresses", () => getData(data?.data?.token));
}
