import React from "react";
import { Link } from "react-router-dom";
import { useURLQuery } from "../../App";

const CategoryCard = ({ product_group }) => {
  let query = useURLQuery();
  const main_category_code = query.get("main_category_code");
  const sub_category_name = query.get("sub_category_name");
  const sub_category_code = query.get("sub_category_code");
  const { group_name, group_code } = product_group;
  // group_name
  // group_code
  // sub_category_name
  // main_category_code
  const to = `/product?main_category_code=${main_category_code}&sub_category_code=${sub_category_code}&sub_category_name=${sub_category_name}&group_name=${group_name}&group_code=${group_code}`;
  return (
    <Link
      to={to}
      className="flex justify-between items-center px-4 py-6 bg-white rounded-xl xl:w-96 lg:80 w-60  m-4  shadow"
    >
      <div className="font-black text-xs leading-3 cursor-pointer ">
        {group_name}
      </div>
      <span>
        <svg
          width="6"
          height="10"
          viewBox="0 0 6 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.59597 4.99578C5.59668 5.11096 5.57317 5.22514 5.52679 5.33178C5.48041 5.43841 5.41207 5.5354 5.32569 5.61717L1.59758 9.11798C1.51068 9.19958 1.40751 9.26431 1.29397 9.30847C1.18043 9.35264 1.05874 9.37537 0.93584 9.37537C0.812944 9.37537 0.69125 9.35264 0.577709 9.30847C0.464168 9.26431 0.361002 9.19958 0.274101 9.11798C0.1872 9.03637 0.118266 8.9395 0.071236 8.83288C0.0242056 8.72626 -3.51996e-08 8.61199 -3.84128e-08 8.49658C-4.16259e-08 8.38118 0.0242056 8.26691 0.0712359 8.16029C0.118266 8.05367 0.1872 7.95679 0.274101 7.87519L3.35911 4.99578L0.395264 2.10762C0.299927 2.02711 0.223307 1.92899 0.170194 1.81941C0.117081 1.70982 0.0886199 1.59112 0.0865888 1.47074C0.0845576 1.35035 0.109 1.23086 0.158389 1.11975C0.207778 1.00864 0.281051 0.908293 0.373624 0.824985C0.466197 0.741679 0.576079 0.677204 0.696398 0.635594C0.816718 0.593985 0.944886 0.576136 1.07289 0.583161C1.20089 0.590188 1.32597 0.621939 1.44031 0.676429C1.55464 0.730919 1.65578 0.806975 1.73738 0.899843L5.33501 4.40065C5.49934 4.5607 5.59264 4.77348 5.59597 4.99578Z"
            fill="#E7E7E7"
          />
        </svg>
      </span>
    </Link>
  );
};

export default CategoryCard;
