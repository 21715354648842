/* eslint-disable no-unused-vars */
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { getHeaders } from "./helper";
import useProfile from "./useProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const setData = async (props, token) => {
  const config = getHeaders();

  if (!token) {
    return;
  } else {
    config.headers.ab_token = token;
  }

  const { data } = await axios.post(
    baseURL + "cart/addOrUpdateToCart",
    props,
    config
  );

  return data;
};

const options = {
  enableVibrateFallback: true,
  ignoreAndroidSystemSettings: true,
};

const ToastifySuccess = () => {
  toast.success("Product Added to Cart", {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default function useCartMutation() {
  const queryCache = useQueryClient();
  const { data } = useProfile();
  // const { t } = useTranslation();
  const location = useLocation();
  let navigate = useNavigate();

  return useMutation((props) => setData(props, data?.data?.token), {
    onSuccess: (res) => {
      queryCache.invalidateQueries("profile");
      if (res) {
        if (res.status === true) {
          ToastifySuccess();
        }
      } else {
        // redirect the user to the cart page
        navigate(location.pathname + location.search + "&login=true");
      }
    },
    onError: (err) => {},
  });
}
