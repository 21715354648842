import React from "react";

function MinusIcon() {
  return (
    <>
      {" "}
      <svg
        width="16"
        height="3"
        viewBox="0 0 16 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0538 2.53002H8.77227H6.27167H0V0H6.27167H8.77227H15.0538V2.53002Z"
          fill="#241BD0"
        />
      </svg>
    </>
  );
}

export default MinusIcon;
