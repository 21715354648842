import React from "react";

function ContactAddressIcon() {
  return (
    <div>
      <svg
        width="23"
        height="30"
        viewBox="0 0 23 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0316 19.3726L19.264 14.8347C21.9308 11.0813 21.5031 5.99648 18.2471 2.74167C14.5845 -0.913452 8.62558 -0.914732 4.96424 2.74289C1.7082 5.99648 1.28054 11.0813 3.94727 14.8334L7.15237 19.3414C3.18147 20.0475 0 21.7471 0 24.384C0 27.9421 5.78481 29.8033 11.5 29.8033C17.214 29.8033 23 27.9422 23 24.384C23 21.7882 19.918 20.0986 16.0316 19.3726ZM5.97376 13.3938C4.01194 10.635 4.32644 6.89412 6.72221 4.50214C8.06864 3.15693 9.8365 2.48438 11.6056 2.48438C13.3735 2.48438 15.1414 3.15699 16.4866 4.50092C18.8824 6.8929 19.1969 10.6338 17.2363 13.3926L11.5995 21.3071L5.97376 13.3938ZM11.5 27.3156C5.66793 27.3156 2.48651 25.3775 2.48651 24.3828C2.48651 23.5585 4.69578 22.0953 8.76988 21.6166L10.5862 24.1715C10.8187 24.4997 11.1967 24.6949 11.5995 24.6949C12.0011 24.6949 12.379 24.5009 12.6115 24.174L14.4167 21.6403C18.3715 22.1413 20.5135 23.571 20.5135 24.3828C20.5135 25.3774 17.332 27.3156 11.5 27.3156Z"
          fill="#CB8A0A"
        />
        <path
          d="M11.5995 5.13721C9.25476 5.13721 7.34766 7.04437 7.34766 9.38908C7.34766 11.7339 9.25482 13.641 11.5995 13.641C13.943 13.641 15.8514 11.7338 15.8514 9.38908C15.8515 7.04431 13.9443 5.13721 11.5995 5.13721ZM11.5995 11.1545C10.626 11.1545 9.83411 10.3626 9.83411 9.38908C9.83411 8.4156 10.626 7.62366 11.5995 7.62366C12.573 7.62366 13.365 8.4156 13.365 9.38908C13.365 10.3626 12.573 11.1545 11.5995 11.1545Z"
          fill="#CB8A0A"
        />
      </svg>
    </div>
  );
}

export default ContactAddressIcon;
