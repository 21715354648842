/* eslint-disable no-unused-vars */

import React from "react";
import ImageSlider from "./ImageSlider";
import PricingDetails from "./PricingDetails";

const ProductDetails = ({ product_pictures, product_details }) => {
  return (
    <div className="flex">
      <div className="flex flex-1">
        <ImageSlider
          product_details={product_details}
          product_pictures={product_pictures}
        />
      </div>
      <div className="flex flex-1">
        <PricingDetails
          product_details={product_details}
          product_pictures={product_pictures}
        />
      </div>
    </div>
  );
};

export default ProductDetails;
