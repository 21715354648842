import React from "react";
import { useNavigate } from "react-router-dom";
import useFavoriteMutation from "../../queries/useFavoriteMutation";
import CartIcon from "../Svg/CartIcon";
import Loader from "../Svg/Loader";
import DeleteIcon from "../Svg/DeleteIcon";

const FavouritesCard = ({ item }) => {
  const { mutate: mutation, isLoading } = useFavoriteMutation();

  const removeFromFavourites = () => {
    mutation({
      item_code: item.item_code,
      item_unit: item.ecomm_unit,
      quantity: item.cart_quantity,
      cart_price: item.ecomm_selling_price,
      list_price: item.ecomm_list_price,
      device_name: "web",
    });
  };

  let navigate = useNavigate();

  const itemDetail = () => {
    navigate(`/productdetail?item_code=${item.item_code}`);
  };

  return (
    <div className="lg:w-96 lg:h-24 w-72 h-20 bg-white rounded-2xl border flex m-2">
      <div
        className="w-28 h-full rounded-2xl border-4 border-white bg-red-400"
        style={{
          backgroundColor: "#EFEFEF",
          backgroundImage: `url(${item.item_picture})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        onClick={itemDetail}
      ></div>
      <div className="flex flex-row items-center justify-between w-full px-4">
        <div>
          <h1
            className="font-light text-lg cursor-pointer"
            onClick={itemDetail}
          >
            {item.item_name}
          </h1>
          <p className="font-black ">
            <span style={{ color: "#069843" }} className="">
              ฿{item.ecomm_selling_price}
            </span>
            <span className="text-gray-400">/{item.ecomm_unit} </span>
          </p>
        </div>
        <div className="flex ">
          <div className="mr-3 cursor-pointer" onClick={itemDetail}>
            <CartIcon />
          </div>
          <div className="cursor-pointer" onClick={removeFromFavourites}>
            {isLoading ? <Loader /> : <DeleteIcon />}
          </div>
        </div>
      </div>
    </div>
  );
};
export default FavouritesCard;
