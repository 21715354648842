import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../constants/AppContext";
// import useCartItems from "../../queries/useCartItems";
import useProfile from "../../queries/useProfile";
import NavNotificationIcon from "../Svg/NavNotificationIcon";
import ThreeLineIcon from "../Svg/ThreeLineIcon";
import { AiFillHome, AiOutlineShoppingCart } from "react-icons/ai";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { BsFillCartFill } from "react-icons/bs";
import Popup from "reactjs-popup";

const NavBar = () => {
  const location = useLocation();
  const path = location.pathname;
  const { user } = React.useContext(AppContext);
  const { data: Profile } = useProfile();
  let navigate = useNavigate();
  function redirectHomepage() {
    navigate("/");
  }

  const options = ["one", "two", "three"];
  const defaultOption = options[0];

  return (
    <div className="flex sm:flex-row flex-col justify-evenly bg-white">
      <div className="w-full sm:flex hidden flex-1/3  h-16 relative">
        <div className="absolute top-8 lg:left-10 left-12 z-50 w-20 ">
          <img
            src="/pnd-logo.png"
            alt="logo"
            className="w-20 h-20 cursor-pointer"
            onClick={redirectHomepage}
          />
        </div>
      </div>

      <div className="w-full flex flex-1/3  h-16 justify-evenly items-center">
        <div
          className="flex items-center justify-center h-full  border-b-4 sm:px-4 px-1"
          style={{
            borderColor: path === "/" ? "#ffc107" : "transparent",
          }}
        >
          <Link to="/" className="flex items-center justify-center ">
            {path === "/" ? (
              <span className="pr-4  text-2xl text-primary">
                <AiFillHome />
              </span>
            ) : (
              <span className="pr-4  text-2xl text-base">
                <AiFillHome />
              </span>
            )}
            <h1
              className="font-extrabold text-primary"
              style={{
                color: path === "/" ? "#241BD0" : "#636363",
                fontWeight: path === "/" ? "bold" : "normal",
              }}
            >
              Home
            </h1>
          </Link>
        </div>
        <div
          className="flex items-center justify-center h-full border-b-4 sm:px-4 px-1"
          style={{
            borderColor: path === "/favourites" ? "#ffc107" : "transparent",
          }}
        >
          <Link
            to={user ? "/favourites" : "/favourites?login=true"}
            className="flex items-center justify-center"
          >
            {path === "/favourites" ? (
              <span className="pr-4  text-2xl text-primary">
                <FaHeart />
              </span>
            ) : (
              <span className="pr-4  text-2xl text-base">
                <FaRegHeart />
              </span>
            )}
            <h1
              className="font-semibold text-gray-500"
              style={{
                color: path === "/favourites" ? "#241BD0" : "#636363",
                fontWeight: path === "/favourites" ? "bold" : "normal",
              }}
            >
              Favourites
            </h1>
          </Link>
        </div>
        <div
          className="flex items-center justify-center h-full border-b-4 sm:px-4 px-1"
          style={{
            borderColor: path === "/cart" ? "#ffc107" : "transparent",
          }}
        >
          <Link
            to={user ? "/cart" : "/cart?login=true"}
            className="flex items-center justify-center"
          >
            {/* {path === "/cart" ? (
              <span className="pr-4  text-2xl text-primary">
                <AiOutlineShoppingCart />
              </span>
            ) : (
              <span className="pr-4  text-2xl text-base">
                <AiOutlineShoppingCart />
              </span>
            )} */}
            <span className="pr-4 flex relative">
              {path === "/cart" ? (
                <span className="  text-2xl text-primary">
                  <BsFillCartFill />
                </span>
              ) : (
                <span className="  text-2xl text-base">
                  <AiOutlineShoppingCart />
                </span>
              )}
              {Profile?.data?.cart_count > 0 ? (
                <span
                  className="absolute -top-2 right-1  w-4 h-4 rounded-full bg-yellow-400 flex flex-col justify-center items-center"
                  style={{ fontSize: "10px" }}
                >
                  {Profile?.data?.cart_count}
                </span>
              ) : null}
            </span>
            <h1
              className="font-semibold text-gray-500"
              style={{
                color: path === "/cart" ? "#241BD0" : "#636363",
                fontWeight: path === "/cart" ? "bold" : "normal",
              }}
            >
              Cart
            </h1>
          </Link>
        </div>
        {/* <div
          className="flex items-center justify-center h-full border-b-4 sm:px-4 px-1"
          style={{
            borderColor: path === "/myorders" ? "#ffc107" : "transparent",
          }}
        >
          <Link to="/myorders" className="flex items-center justify-center">
            <span className="pr-4 relative">{Profile?.data?.order_count}</span>
            <h1
              className="font-semibold text-gray-500"
              style={{
                color: path === "/myorders" ? "#241BD0" : "#636363",
                fontWeight: path === "/myorders" ? "bold" : "normal",
              }}
            >
              Orders
            </h1>
          </Link>
        </div> */}

        {/* {user ? (
          <div className="flex items-center justify-center h-full sm:px-4 px-1">
            <Link
              to="/logout"
              className="flex items-center justify-center cursor-pointer"
            >
              <h1 className="font-semibold text-red-500">Logout</h1>
            </Link>
          </div>
        ) : null} */}
      </div>
      <div className="w-full flex flex-1/3  h-16 sm:justify-end justify-evenly">
        <div className="flex justify-end items-center  w-full ">
          <span className="m-9">
            <NavNotificationIcon
              notification_count={Profile?.data?.notification_count}
            />
          </span>
          {user ? (
            <div className="flex items-center ">
              <span className="h-auto min-w-max  ">
                <Link to="/">
                  <img
                    src={Profile?.data?.pp_image}
                    alt="avatar"
                    className="my-avatar"
                  />
                </Link>
              </span>
              <h2 className="ml-2 font-extrabold text-lg text-ellipsis truncate m-5bg-gray-500 w-32">
                {Profile?.data?.customer_name}
              </h2>
            </div>
          ) : (
            <Link to={`${path}?login=true`}>
              <button className="bg-primary text-lg font-extrabold text-white p-3 text-center w-24  cursor-pointer rounded-2xl ">
                Login
              </button>
            </Link>
          )}

          <span className="m-4">
            <Popup
              trigger={
                <div className="menu-item">
                  <ThreeLineIcon />
                </div>
              }
              position="bottom right"
              on="click"
              closeOnDocumentClick
              mouseLeaveDelay={300}
              mouseEnterDelay={0}
              contentStyle={{ padding: "0px", border: "none" }}
              arrow={false}
            >
              <div className="menu">
                {user ? (
                  <div className="menu-item">
                    <div className="">
                      <Link
                        to="/logout"
                        className="flex items-center justify-center cursor-pointer"
                      >
                        <h1 className="font-semibold text-red-500">Logout</h1>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {user ? (
                  <div className="menu-item">
                    <div
                      className=""
                      style={{
                        borderColor:
                          path === "/myorders" ? "#ffc107" : "transparent",
                      }}
                    >
                      <Link
                        to="/myorders"
                        className="flex items-center justify-center"
                      >
                        {/* <span className="pr-4 relative">
                        {Profile?.data?.order_count}
                      </span> */}
                        <h1
                          className="font-semibold text-gray-500"
                          style={{
                            color: path === "/myorders" ? "#241BD0" : "#636363",
                            fontWeight:
                              path === "/myorders" ? "bold" : "normal",
                          }}
                        >
                          Orders
                        </h1>
                      </Link>
                    </div>
                  </div>
                ) : null}

                <div className="menu-item">
                  <div className="">
                    <Link
                      to="/contactus"
                      className="flex items-center justify-center cursor-pointer"
                    >
                      <h1 className="font-semibold ">Contact Us</h1>
                    </Link>
                  </div>
                </div>
              </div>
            </Popup>
            {/* <Popup
              trigger={
                <button className="button">
                  <ThreeLineIcon />
                </button>
              }
              position="bottom center"
              nested
            >
              <span className="border border-black">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae
                magni omnis delectus nemo, maxime molestiae dolorem numquam
                mollitia, voluptate ea, accusamus excepturi deleniti ratione
                sapiente! Laudantium, aperiam doloribus. Odit, aut.
              </span>
            </Popup> */}
          </span>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
