/* eslint-disable no-unused-vars */
import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import Modal from "react-modal";
import { useURLQuery } from "../Pages/LoginPage";
import { AppContext } from "../constants/AppContext";
import useProfile from "../queries/useProfile";
import LoginScreen from "../Pages/LoginPage";
import RegistrationScreen from "../components/Popup/RegistrationScreen";
import { ToastContainer } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    // dark overlay ocolor
    backgroundColor: "rgba(0, 0, 0, 0.3)",
  },
};
Modal.setAppElement("#root");

const HomeLayout = ({ children }) => {
  const query = useURLQuery();
  const login = query.get("login");
  const location = useLocation();
  const navigate = useNavigate();
  const registration = query.get("registration");
  const { initializing } = useContext(AppContext);

  const { data, status, error } = useProfile();

  if (status === "loading" || initializing) {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (status === "error") {
    return <h1>{error.message}</h1>;
  }

  function closeModal() {
    const { search } = location;
    const parsed = queryString.parse(search);
    delete parsed.login;
    delete parsed.registration;
    const stringified = queryString.stringify(parsed);
    navigate(location.pathname + "?" + stringified);
  }

  return (
    <>
      <Modal
        isOpen={login === "true" || registration === "true"}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <LoginScreen closeModal={closeModal} />
      </Modal>
      <ToastContainer />
      {children}
    </>
  );
};

export default HomeLayout;
