import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "react-awesome-slider/dist/styles.css";
import { AppProvider } from "./constants/AppContext";
import "./firebase";
import "react-toastify/dist/ReactToastify.css";
import MobileView from "./components/Mobile/MobileView";

const queryClient = new QueryClient();
const width = window.innerWidth;
if (width > 768) {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppProvider>
            <App />
          </AppProvider>
        </Router>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <Router>
          <AppProvider>
            <MobileView />
          </AppProvider>
        </Router>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

reportWebVitals();
