import React from "react";

const OrderCard = ({ order }) => {
  return (
    <div
      style={{ width: "480px" }}
      className="bg-white h-52 lg:m-4 m-2 rounded-2xl "
    >
      <div
        style={{ backgroundColor: "#F3F3F3" }}
        className=" bg-gray rounded-2xl px-4 py-2 flex justify-between m-2 "
      >
        <h1>
          <span>Order ID : </span>
          <span className="font-extrabold"> #{order.order_id}</span>
        </h1>
        <h1>
          <span>Order Date : </span>
          <span className="font-extrabold"> {order.order_date}</span>
        </h1>
      </div>
      <div>
        <div className="flex">
          <div
            className="w-44 h-32 m-4 rounded-2xl  bg-red-400"
            style={{
              backgroundColor: "#EFEFEF",
              backgroundImage: `url(${order.item_picture})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="flex flex-col py-4 justify-between w-full px-4">
            <div>
              <h1 className="text-lg ">
                Tracking Number :
                <span className="font-extrabold">#{order.order_id_label} </span>
              </h1>
              <h2 className="text-primary text-sm">
                Estimated deliery on 10 Dec 2020
              </h2>
            </div>
            <div className="flex">
              <button
                style={{ border: "1px solid #222222" }}
                className=" w-full rounded-2xl py-2 text-center mr-2 cursor-pointer"
              >
                Order Details
              </button>
              <button className="border w-full rounded-2xl py-2 text-center border-primary text-primary cursor-pointer">
                Track Shipment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCard;
