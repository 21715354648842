import React from "react";
import OrderCard from "../components/MyOrder/OrderCard";
import NavBar from "../components/NavBar/NavBar";
import useOrders from "../queries/useOrders";
import { Link, useNavigate } from "react-router-dom";

const OrdersPage = () => {
  const navigate = useNavigate();
  const { data, status, error } = useOrders();

  if (status === "loading") {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (status === "error") {
    return <div> {error.message}</div>;
  }

  const orders = data?.data || [];

  return (
    <div style={{ backgroundColor: "#F3F3F3" }} className="">
      <div className="">
        <NavBar />
      </div>
      <nav
        className="rounded-md w-full h-16 px-36 py-4 sm:mb-16"
        style={{ backgroundColor: "#F0EFFF" }}
      >
        <ol className="list-reset flex text-black font-black">
          <li className=" px-4 mt-1.5">
            <span
              className="text-primary font-black cursor-pointer "
              onClick={() => navigate(-1)}
            >
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.66667 2.18094e-05C6.9315 -0.00148883 7.19079 0.0743912 7.41148 0.217988C7.63218 0.361583 7.80427 0.56639 7.90583 0.806294C8.00738 1.0462 8.0338 1.31033 7.9817 1.56502C7.92961 1.81971 7.80137 2.05341 7.61333 2.23633L3.21333 6.53895L7.61333 10.8416C7.83177 11.0917 7.94591 11.4136 7.93295 11.7427C7.91999 12.0718 7.78088 12.384 7.54342 12.617C7.30596 12.8499 6.98764 12.9863 6.65207 12.999C6.3165 13.0117 5.9884 12.8998 5.73333 12.6855L0.399999 7.4544C0.151665 7.20937 0.0122761 6.87791 0.0122761 6.53241C0.0122761 6.18691 0.151665 5.85545 0.399999 5.61042L5.73333 0.379281C5.98168 0.137675 6.31688 0.00146666 6.66667 2.18094e-05Z"
                  fill="#241BD0"
                />
                <path
                  d="M1.33333 5.23116L14.6667 5.23116C15.0203 5.23116 15.3594 5.36895 15.6095 5.6142C15.8595 5.85946 16 6.1921 16 6.53895C16 6.88579 15.8595 7.21843 15.6095 7.46369C15.3594 7.70895 15.0203 7.84673 14.6667 7.84673L1.33333 7.84673C0.979712 7.84673 0.640572 7.70895 0.390523 7.46369C0.140475 7.21843 4.27305e-07 6.88579 3.97445e-07 6.53895C3.67585e-07 6.1921 0.140475 5.85946 0.390523 5.6142C0.640572 5.36895 0.979712 5.23116 1.33333 5.23116Z"
                  fill="#241BD0"
                />
              </svg>
            </span>
          </li>
          <li className=" text-lg w-full flex justify-center">
            <Link to="/" className="">
              My Orders
            </Link>
          </li>
        </ol>
      </nav>
      <div className=" flex justify-center">
        <div className="flex flex-wrap lg:max-w-7xl justify-center">
          {orders.map((order, index) => {
            return <OrderCard key={index} order={order} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
