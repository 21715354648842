import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { getHeaders } from "./helper";
import useProfile from "./useProfile";

const setData = async (props, token) => {
  const config = getHeaders();

  if (!token) {
    return;
  } else {
    config.headers.ab_token = token;
  }

  const { data } = await axios.post(
    baseURL + "cart/removeFromCart",
    props,
    config
  );
  return data;
};

export default function useCartRemoveMutation() {
  const queryCache = useQueryClient();
  const { data } = useProfile();
  return useMutation((props) => setData(props, data?.data?.token), {
    onSuccess: (res) => {
      queryCache.invalidateQueries("cartItems");
    },
  });
}
