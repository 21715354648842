import React from "react";
import { Link } from "react-router-dom";

const CategoryCard = ({ category }) => {
  const { background_mobile_url, sub_categories = [] } = category;
  const [cliked, setClicked] = React.useState(false);
  return (
    <div>
      <div
        className={`lg:w-96 w-80 lg:h-44 h-36 border cursor-pointer`}
        onClick={() => setClicked(!cliked)}
        style={{
          backgroundImage: `url(${background_mobile_url})`,
          backgroundSize: "center",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="flex  items-center pl-10 pt-10">
          <span className={`${cliked ? "rotate-icon-90" : "rotate-icon-0"}  `}>
            <svg
              width={6}
              height={8}
              viewBox="0 0 6 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.78389 4.17508C5.78455 4.27567 5.76264 4.37539 5.71941 4.46852C5.67619 4.56165 5.6125 4.64635 5.532 4.71777L2.05772 7.77521C1.97673 7.84648 1.88059 7.90301 1.77478 7.94158C1.66897 7.98015 1.55556 8 1.44103 8C1.3265 8 1.2131 7.98015 1.10728 7.94158C1.00147 7.90301 0.905332 7.84648 0.824348 7.77521C0.743363 7.70394 0.679123 7.61933 0.635295 7.52622C0.591466 7.4331 0.568909 7.3333 0.568909 7.23251C0.568909 7.13173 0.591466 7.03192 0.635295 6.93881C0.679123 6.84569 0.743363 6.76109 0.824348 6.68982L3.69932 4.17508L0.937262 1.65269C0.848416 1.58238 0.777012 1.49669 0.727515 1.40098C0.678019 1.30527 0.651495 1.20161 0.649602 1.09647C0.647709 0.991328 0.670488 0.886975 0.716514 0.789935C0.76254 0.692896 0.830825 0.605258 0.917095 0.532502C1.00337 0.459745 1.10577 0.403436 1.21789 0.367096C1.33002 0.330756 1.44946 0.315167 1.56875 0.321304C1.68804 0.32744 1.8046 0.35517 1.91115 0.402759C2.0177 0.450348 2.11195 0.516772 2.188 0.597878L5.54069 3.65531C5.69383 3.7951 5.78078 3.98093 5.78389 4.17508Z"
                fill="white"
              />
            </svg>
          </span>
          <h1 className="text-center text-sm font-black ml-3 text-white">
            {category.main_category_name}
          </h1>
        </div>
      </div>
      {cliked && (
        <div className="flex flex-wrap items-center  ">
          {sub_categories.map((subCategory, index) => {
            const to = `/category?main_category_code=${category.main_category_code}&sub_category_code=${subCategory.sub_category_code}&sub_category_name=${subCategory.sub_category_name}`;
            return (
              <Link
                key={index}
                to={to}
                className={`
                border-0 border-b  pt-2 pb-1 px-2 w-full cursor-pointer text-sm font-semibold flex justify-between`}
              >
                <span> {subCategory.sub_category_name} </span>
                <span className="mt-1">
                  <svg
                    width="7"
                    height="11"
                    viewBox="0 0 7 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.34665 5.11299C6.34745 5.24362 6.32079 5.37312 6.26819 5.49406C6.21558 5.615 6.13807 5.72499 6.0401 5.81774L1.81189 9.78816C1.71333 9.88071 1.59632 9.95412 1.46755 10.0042C1.33878 10.0543 1.20076 10.0801 1.06138 10.0801C0.921997 10.0801 0.783979 10.0543 0.655206 10.0042C0.526434 9.95412 0.409429 9.88071 0.31087 9.78816C0.212312 9.69561 0.134131 9.58574 0.080792 9.46482C0.0274527 9.3439 -3.99215e-08 9.2143 -4.35657e-08 9.08341C-4.72098e-08 8.95253 0.0274526 8.82292 0.0807919 8.702C0.134131 8.58108 0.212312 8.47121 0.31087 8.37866L3.80972 5.11299L0.448287 1.83739C0.340161 1.74608 0.253262 1.6348 0.193025 1.51052C0.132787 1.38623 0.100508 1.25161 0.0982043 1.11507C0.0959007 0.978538 0.123622 0.843023 0.179636 0.717007C0.235651 0.59099 0.318753 0.477182 0.423744 0.382701C0.528736 0.288218 0.653358 0.215095 0.789817 0.167903C0.926277 0.120711 1.07164 0.100468 1.21681 0.108437C1.36198 0.116405 1.50384 0.152415 1.63352 0.214215C1.76319 0.276015 1.87789 0.362274 1.97044 0.467599L6.05068 4.43802C6.23705 4.61954 6.34287 4.86087 6.34665 5.11299Z"
                      fill="#222222"
                    />
                  </svg>
                </span>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CategoryCard;
