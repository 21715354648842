import { useInfiniteQuery } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { getHeaders } from "./helper";
import useProfile from "./useProfile";

const getData = async (props, token) => {
  const config = getHeaders();

  if (token) {
    config.headers.ab_token = token;
  }

  const { data } = await axios.post(baseURL + "masters/items", props, config);
  return data;
};

export default function useProducts(props) {
  const { data } = useProfile();
  return useInfiniteQuery(
    "products/" + props.group_name.toString(),
    ({ pageParam = 1 }) => {
      props.start_from = 0 + (pageParam - 1) * 50;
      return getData(props, data?.data?.token);
    },
    {
      getNextPageParam: (_lastPage, pages) => {
        const { recordsFiltered } = _lastPage;
        if (recordsFiltered > pages.length * 50) {
          return pages.length + 1;
        }
        return null;
      },
    }
  );
}
