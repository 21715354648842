import React from "react";

const Button = (props) => (
  <button
    {...props}
    disabled={props.loading}
    loading={props?.loading?.toString()}
    className="bg-primary font-black text-lg w-full text-white h-12 rounded-xl my-4"
  >
    {props.loading ? "Loading..." : props.children}
  </button>
);

export default Button;
