export const getHeaders = () => {
  const config = {
    headers: {
      device_name: "Windows",
      device_os: "Windows 10",
      locale: "en",
    },
  };
  config.headers["Content-Type"] = "application/json";
  return config;
};

// https://www.getpostman.com/collections/db3167c8c4265d55192c
