import { useQuery } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { getHeaders } from "./helper";
import useProfile from "./useProfile";

const getData = async (props, token) => {
  const config = getHeaders();

  if (token) {
    config.headers.ab_token = token;
  }

  const { data } = await axios.post(baseURL + "masters/groups", props, config);
  return data;
};

export default function useProductGroups(props) {
  const { data } = useProfile();
  return useQuery("productGroups", () => getData(props, data?.data?.token));
}
