import React from "react";

function CloseIcon() {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM13.7 12.3C14.1 12.7 14.1 13.3 13.7 13.7C13.3 14.1 12.7 14.1 12.3 13.7L10 11.4L7.7 13.7C7.3 14.1 6.7 14.1 6.3 13.7C5.9 13.3 5.9 12.7 6.3 12.3L8.6 10L6.3 7.7C5.9 7.3 5.9 6.7 6.3 6.3C6.7 5.9 7.3 5.9 7.7 6.3L10 8.6L12.3 6.3C12.7 5.9 13.3 5.9 13.7 6.3C14.1 6.7 14.1 7.3 13.7 7.7L11.4 10L13.7 12.3Z"
          fill="#DADADA"
        />
      </svg>
    </div>
  );
}

export default CloseIcon;
