import React from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { AppContext } from "../constants/AppContext";

const LogOutPage = () => {
  const { setUser } = React.useContext(AppContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    const auth = getAuth();

    signOut(auth)
      .then(() => {
        // Sign-out successful.
        setUser(null);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        // An error happened.
      });
  });

  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <h1>We Are logging Out.....</h1>
    </div>
  );
};

export default LogOutPage;
