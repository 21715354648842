import React from "react";
import { useURLQuery } from "../App";
import NavBar from "../components/NavBar/NavBar";

import ProductDetails from "../components/ProductDetails/ProductDetails";
import useProduct from "../queries/useProduct";

const ProductDetailsPage = () => {
  const query = useURLQuery();
  const item_code = query.get("item_code");

  const { data, status, error } = useProduct({
    item_code: item_code,
  });

  if (status === "loading") {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (status === "error") {
    return <div>{error.message}</div>;
  }

  if (data.status === false) {
    return <div>{data.message}</div>;
  }
  const { product_details, product_pictures } = data.data;
  return (
    <div className="h-screen bg-white">
      <div className="pb-20">
        <NavBar />
      </div>
      <div className="lg:max-w-7xl mx-auto ">
        <ProductDetails
          product_details={product_details}
          product_pictures={product_pictures}
        />
      </div>
    </div>
  );
};

export default ProductDetailsPage;
