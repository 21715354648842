/* eslint-disable no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import useCartRemoveMutation from "../../queries/useCartRemoveMutation";
import DeleteIcon from "../Svg/DeleteIcon";
import Loader from "../Svg/Loader";

const CartCard = ({ item }) => {
  const { item_code } = item;
  const navigate = useNavigate();
  console.log("ITEM", item);

  const { mutate: mutation, isLoading } = useCartRemoveMutation();

  const removeProductFromCart = () => {
    const confirmation = window.confirm(
      "Are you sure you want to remove this item?"
    );
    if (confirmation) {
      mutation({
        item_code: item_code,
      });
    }
  };

  return (
    <>
      <div className="lg:w-96 lg:h-28 w-80 h-28 bg-white rounded-2xl border flex lg:m-2 m-1">
        <div
          className="w-36 h-full rounded-2xl border-4 border-white bg-red-400 cursor-pointer"
          style={{
            backgroundColor: "#EFEFEF",
            backgroundImage: `url(${
              item.item_picture
            }?number=${Math.random()})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          onClick={() => navigate(`/productdetail?item_code=${item.item_code}`)}
        ></div>
        <div className="flex flex-row items-center justify-between w-full px-4">
          <div>
            <h1
              className="font-extrabold text-lg cursor-pointer "
              onClick={() =>
                navigate(`/productdetail?item_code=${item.item_code}`)
              }
            >
              {item?.item_name}
            </h1>
            <h2>
              <span>Quantity : </span>
              <span className="text-gray-400 font-extrabold">
                {item?.quantity}/{item?.ecomm_unit}
              </span>
            </h2>
            <p className="font-black ">
              <span className="font-normal">Price : </span>
              <span style={{ color: "#069843" }} className="">
                {item?.ecomm_selling_price}
              </span>
              <span className="text-gray-400">/Yard</span>
            </p>
          </div>
          <div className="flex">
            <div className="cursor-pointer" onClick={removeProductFromCart}>
              {isLoading ? <Loader /> : <DeleteIcon />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartCard;
