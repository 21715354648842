import React from "react";

function PlusIcon() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.77227 8.76247H15.0538V6.23244H8.77227V0H6.27167V6.23244H0V8.76247H6.27167V15.0047H8.77227V8.76247Z"
          fill="#241BD0"
        />
      </svg>
    </>
  );
}

export default PlusIcon;
