import React from "react";

function SelectIcon() {
  return (
    <>
      <svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11.5" cy="11.5" r="11.5" fill="#241BD0" />
        <path
          d="M15.3376 8L10.1128 13.1421L7.4357 10.545L6 11.9322L10.2208 16L17 9.64799L15.3376 8Z"
          fill="white"
        />
      </svg>
    </>
  );
}

export default SelectIcon;
