import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useURLQuery } from "../App";
import ProductCard from "../components/CategoryCards/ProductCard";
import NavBar from "../components/NavBar/NavBar";
import useProducts from "../queries/useProducts";

const ProductPage = () => {
  let query = useURLQuery();
  const navigate = useNavigate();
  const main_category_code = query.get("main_category_code");
  const sub_category_name = query.get("sub_category_name");
  const sub_category_code = query.get("sub_category_code");
  const group_name = query.get("group_name");
  const group_code = query.get("group_code");

  const ProductListRequest = {
    group_code,
    group_name,
    main_category_code,
    start_from: 0,
  };

  const { data, status, error, fetchNextPage, hasNextPage } =
    useProducts(ProductListRequest);

  if (status === "loading") {
    return (
      <div className="loader-container">
        <div className="loader"></div>
      </div>
    );
  }

  if (status === "error") {
    return <div>{error.message}</div>;
  }
  const { pages } = data;
  const to_category = `/category?main_category_code=${main_category_code}&sub_category_code=${sub_category_code}&sub_category_name=${sub_category_name}`;

  return (
    <div className="h-screen">
      <div className="">
        <NavBar />
      </div>
      <nav
        className="rounded-md w-full h-16 px-36 py-4"
        style={{ backgroundColor: "#F0EFFF" }}
      >
        <ol className="list-reset flex text-black font-black ">
          <li className=" px-3 mt-1.5" onClick={() => navigate(-1)}>
            <span className="text-primary font-black  ">
              <svg
                width="16"
                height="13"
                viewBox="0 0 16 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.66667 2.18094e-05C6.9315 -0.00148883 7.19079 0.0743912 7.41148 0.217988C7.63218 0.361583 7.80427 0.56639 7.90583 0.806294C8.00738 1.0462 8.0338 1.31033 7.9817 1.56502C7.92961 1.81971 7.80137 2.05341 7.61333 2.23633L3.21333 6.53895L7.61333 10.8416C7.83177 11.0917 7.94591 11.4136 7.93295 11.7427C7.91999 12.0718 7.78088 12.384 7.54342 12.617C7.30596 12.8499 6.98764 12.9863 6.65207 12.999C6.3165 13.0117 5.9884 12.8998 5.73333 12.6855L0.399999 7.4544C0.151665 7.20937 0.0122761 6.87791 0.0122761 6.53241C0.0122761 6.18691 0.151665 5.85545 0.399999 5.61042L5.73333 0.379281C5.98168 0.137675 6.31688 0.00146666 6.66667 2.18094e-05Z"
                  fill="#241BD0"
                />
                <path
                  d="M1.33333 5.23116L14.6667 5.23116C15.0203 5.23116 15.3594 5.36895 15.6095 5.6142C15.8595 5.85946 16 6.1921 16 6.53895C16 6.88579 15.8595 7.21843 15.6095 7.46369C15.3594 7.70895 15.0203 7.84673 14.6667 7.84673L1.33333 7.84673C0.979712 7.84673 0.640572 7.70895 0.390523 7.46369C0.140475 7.21843 4.27305e-07 6.88579 3.97445e-07 6.53895C3.67585e-07 6.1921 0.140475 5.85946 0.390523 5.6142C0.640572 5.36895 0.979712 5.23116 1.33333 5.23116Z"
                  fill="#241BD0"
                />
              </svg>
            </span>
          </li>
          <li className="">
            <Link to="/" className="">
              Home
            </Link>
          </li>
          <li className=" px-3 mt-1.5">
            <span className="text-primary font-black  ">
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99998 6.31038C8.00099 6.47504 7.96738 6.63827 7.90107 6.79072C7.83477 6.94316 7.73707 7.08181 7.61357 7.19872L2.28389 12.2034C2.15966 12.3201 2.01217 12.4126 1.84985 12.4758C1.68754 12.5389 1.51356 12.5714 1.33787 12.5714C1.16218 12.5714 0.988208 12.5389 0.82589 12.4758C0.663572 12.4126 0.516086 12.3201 0.391853 12.2034C0.26762 12.0868 0.169073 11.9483 0.101839 11.7959C0.0346042 11.6435 -5.03212e-08 11.4801 -5.49147e-08 11.3151C-5.95082e-08 11.1501 0.0346042 10.9868 0.101839 10.8343C0.169073 10.6819 0.26762 10.5434 0.391853 10.4268L4.80217 6.31038L0.565068 2.18148C0.428775 2.06638 0.319238 1.92611 0.243309 1.76945C0.167379 1.61278 0.126691 1.44309 0.123787 1.27099C0.120883 1.09888 0.155826 0.928067 0.226432 0.769223C0.297039 0.610378 0.401789 0.466923 0.534131 0.347828C0.666473 0.228733 0.82356 0.13656 0.995568 0.0770752C1.16758 0.0175901 1.3508 -0.00792694 1.53379 0.00211752C1.71678 0.012162 1.8956 0.057553 2.05905 0.135452C2.22251 0.213351 2.36709 0.32208 2.48375 0.454843L7.6269 5.45957C7.86183 5.68838 7.99521 5.99257 7.99998 6.31038Z"
                  fill="#241BD0"
                />
              </svg>
            </span>
          </li>
          <li className="">
            <Link to={to_category}>{sub_category_name}</Link>
          </li>
          <li className=" px-3 mt-1.5">
            <span className="text-primary font-black  ">
              <svg
                width="8"
                height="13"
                viewBox="0 0 8 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99998 6.31038C8.00099 6.47504 7.96738 6.63827 7.90107 6.79072C7.83477 6.94316 7.73707 7.08181 7.61357 7.19872L2.28389 12.2034C2.15966 12.3201 2.01217 12.4126 1.84985 12.4758C1.68754 12.5389 1.51356 12.5714 1.33787 12.5714C1.16218 12.5714 0.988208 12.5389 0.82589 12.4758C0.663572 12.4126 0.516086 12.3201 0.391853 12.2034C0.26762 12.0868 0.169073 11.9483 0.101839 11.7959C0.0346042 11.6435 -5.03212e-08 11.4801 -5.49147e-08 11.3151C-5.95082e-08 11.1501 0.0346042 10.9868 0.101839 10.8343C0.169073 10.6819 0.26762 10.5434 0.391853 10.4268L4.80217 6.31038L0.565068 2.18148C0.428775 2.06638 0.319238 1.92611 0.243309 1.76945C0.167379 1.61278 0.126691 1.44309 0.123787 1.27099C0.120883 1.09888 0.155826 0.928067 0.226432 0.769223C0.297039 0.610378 0.401789 0.466923 0.534131 0.347828C0.666473 0.228733 0.82356 0.13656 0.995568 0.0770752C1.16758 0.0175901 1.3508 -0.00792694 1.53379 0.00211752C1.71678 0.012162 1.8956 0.057553 2.05905 0.135452C2.22251 0.213351 2.36709 0.32208 2.48375 0.454843L7.6269 5.45957C7.86183 5.68838 7.99521 5.99257 7.99998 6.31038Z"
                  fill="#241BD0"
                />
              </svg>
            </span>
          </li>
          <li>{group_name}</li>

          <li>
            <Link to="/productdetailspage"></Link>
          </li>
        </ol>
      </nav>
      <div className="px-10 flex flex-wrap justify-center items-center">
        {pages.map((page, index) => {
          const { data: products } = page;
          return (
            <div
              className=" w-full pl-7 flex justify-center  flex-wrap "
              key={index}
            >
              {products.map((product, jindex) => (
                <ProductCard key={jindex} product={product} />
              ))}
            </div>
          );
        })}
        {hasNextPage ? (
          <div
            className="cursor-pointer text-lg font-bold m-10"
            onClick={fetchNextPage}
          >
            Load More
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ProductPage;
