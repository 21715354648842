import React from "react";
import SelectIcon from "../Svg/SelectIcon";
import UnSelectIcon from "../Svg/UnSelectIcon";

const AdressCard = ({ address, selectedAddress, setSelectedAddress }) => {
  return (
    <div
      style={{ width: "420px" }}
      className=" bg-white rounded-2xl p-4 h-28 flex m-4"
    >
      <div
        className="px-4 my-auto cursor-pointer"
        onClick={() => setSelectedAddress(address.address_code)}
      >
        {selectedAddress === address.address_code ? (
          <SelectIcon />
        ) : (
          <UnSelectIcon />
        )}
      </div>
      <div className>
        <h1 className="font-extrabold pb-2">{address.contact_person}</h1>
        <p className="text-sm">
          {`${address.address_text}, ${address.address_code}, ${address.address_city}, ${address.address_state}, ${address.address_pin}`}
        </p>
      </div>
    </div>
  );
};

export default AdressCard;
