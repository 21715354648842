/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import { toast } from "react-toastify";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import CloseIcon from "../Svg/CloseIcon";
import BackIcon from "../Svg/BackIcon";
import Button from "../Core/Button";
import { useMutation, useQueryClient } from "react-query";
import { firbaseConfirmation, firbaseLogin } from "../../queries/firebaseApi";
import { AppContext } from "../../constants/AppContext";

const OTPForm = ({ closeModal, backtoLogin }) => {
  const queryClient = useQueryClient();
  const { setConfirmation } = React.useContext(AppContext);
  const [verificationCode, setVerificationCode] = useState("");
  const [counter, setCounter] = React.useState(30);

  function handleVerificationCode(e) {
    if (e.target.value.length <= 6) {
      setVerificationCode(e.target.value);
    }
  }

  const { mutate: login, isLoading: loginLoading } = useMutation(firbaseLogin, {
    onSuccess: (data) => {
      window.confirmationResult = data;
      setConfirmation(data);
    },
    onError: (error) => {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
  });

  const { mutate: confirmation, loading: confirmationLoading } = useMutation(
    firbaseConfirmation,
    {
      onSuccess: (data) => {
        toast.success("OTP Verified", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        closeModal();
        setConfirmation(null);
        queryClient.invalidateQueries("profile");
      },
      onError: (error) => {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      },
    }
  );

  const onSubmitForm = (e) => {
    e.preventDefault();
    confirmation({ verificationCode });
  };

  function hiddenCaptcha() {
    const auth = getAuth();
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          console.log({ response });
          // login({ phoneNumber });
        },
      },
      auth
    );
  }

  React.useEffect(() => {
    hiddenCaptcha();
  }, []);

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  console.log({ counter });

  return (
    <form onSubmit={onSubmitForm}>
      <div className="flex justify-between  w-full h-16">
        <h1 className="font-extrabold text-lg flex  pt-4">
          <span onClick={backtoLogin} className="cursor-pointer">
            <BackIcon />
          </span>
          Registration
        </h1>
        <div
          className="flex px-4 justify-end cursor-pointer "
          onClick={closeModal}
        >
          <CloseIcon />
        </div>
      </div>

      <div className="mt-10 mb-8 ">
        <h2 className="text-lg font-black text-primary text-center mb-4">
          One Time Password
        </h2>
        <h2 className="font-light text-base text-center">
          Please enter 6 digit OTP sent on mobile number{" "}
          <span className="font-extrabold">{`${window.mobileNumber}`} </span>
        </h2>
      </div>

      <h2 className="font-light pb-1 mt-3 w-full text-left">
        Enter Mobile Number
      </h2>

      <div
        style={{ backgroundColor: "#DFDFDF" }}
        className="rounded-xl flex h-12"
      >
        <input
          style={{
            letterSpacing: "0.5rem",
          }}
          value={verificationCode}
          type="number"
          placeholder="- - - - - - "
          className="outline-none font-semibold p-4 pl-6 rounded-xl text-3xl bg-transparent w-full"
          onChange={handleVerificationCode}
          autoFocus
        />
      </div>
      <div id="sign-in-button"></div>
      <Button type="submit" loading={confirmationLoading}>
        Continue
      </Button>
      {counter > 0 ? (
        <h2 className="my-4 text-base text-center">
          Resend in{" "}
          <span className="text-primary font-extrabold">{counter} Seconds</span>
        </h2>
      ) : (
        <h2
          className="my-4 text-base text-center cursor-pointer"
          onClick={() => {
            login({ phoneNumber: window.mobileNumber });
            setVerificationCode("");
            setCounter(30);
          }}
        >
          <span className="text-primary font-extrabold">Resend</span>
        </h2>
      )}
    </form>
  );
};

export default OTPForm;
