import React from "react";
import CategoryCard from "./CategoryCard";

const SearchCategory = ({ categories = [] }) => {
  return (
    <div>
      <h1 className="text-center  text-sm m-8 font-black">Shop By Category</h1>
      <div className="flex flex-wrap  justify-evenly">
        {categories.map((category) => (
          <CategoryCard
            key={category.main_category_code}
            category={category}
            bg="pink"
          />
        ))}
      </div>
    </div>
  );
};

export default SearchCategory;
