import React from "react";
import ContactAddressIcon from "../Svg/ContactAddressIcon";
import ContactEmailIcon from "../Svg/ContactEmailIcon";
import ContactPhoneIcon from "../Svg/ContactPhoneIcon";
import FacebookIcon from "../Svg/FacebookIcon";
import InstagramIcon from "../Svg/InstagramIcon";
import LineIcon from "../Svg/LineIcon";
import WhatsappIcon from "../Svg/WhatsappIcon";

const Contact = () => {
  return (
    <div className="flex">
      <div className="flex flex-col flex-1">
        <div className="flex pb-8">
          <div className="px-8 mt-2">
            <ContactAddressIcon />
          </div>
          <div className="">
            <h1 className="font-extrabold text-lg pb-2">Address</h1>
            <p>
              677 681 Chakkraphet Rd, Wang Burapha Phirom, Phra Nakhon <br />{" "}
              Bangkok, Thailand 10200
            </p>
          </div>
        </div>
        <div className="flex pb-8">
          <div className="px-8 mt-2">
            <ContactPhoneIcon />
          </div>
          <div className="">
            <h1 className="font-extrabold text-lg pb-2">Phone</h1>
            <p>+66 80 935 0841 &nbsp;&nbsp; | &nbsp;&nbsp; +66 80 935 0841</p>
          </div>
        </div>
        <div className="flex pb-8">
          <div className="px-8 mt-2">
            <ContactEmailIcon />
          </div>
          <div className="">
            <h1 className="font-extrabold pb-2 text-lg">Email</h1>
            <p>info@dmiinternational.net</p>
          </div>
        </div>

        <div className="px-8">
          <h1 className="font-extrabold text-lg">Follow Us</h1>
          <div className="flex space-x-8 py-4">
            <div className="cursor-pointer">
              <FacebookIcon />
            </div>

            <div className="cursor-pointer">
              <InstagramIcon />
            </div>

            <div className="cursor-pointer">
              <WhatsappIcon />
            </div>

            <div className="cursor-pointer">
              <LineIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
