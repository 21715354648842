import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HeartIcon from "../Svg/HeartIcon";

const ProductCard = ({ product }) => {
  const {
    item_name,
    item_picture,
    ecomm_unit,
    ecomm_list_price,
    item_code,
    isFavorite,
  } = product;
  const navigate = useNavigate();
  return (
    <div className="relative lg:w-64 sm:w-56 w-full lg:h-72 pb-4 border rounded-xl lg:m-4 m-2">
      <div className="absolute top-4  right-0 ">
        <HeartIcon isFavorite={isFavorite} item_code={item_code} />
      </div>
      <div
        className="w-full h-32 rounded-xl flex justify-end p-2 cursor-pointer image_placeholder"
        style={{
          backgroundImage: `url(${item_picture})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "#EFEFEF",
        }}
        onClick={() => {
          navigate(`/productdetail?item_code=${item_code}`);
        }}
      ></div>

      <h1 className=" text-base px-4 py-2">
        <Link to={`/productdetail?item_code=${item_code}`}>{item_name}</Link>
      </h1>
      <p className="font-extrabold px-6 ">
        <span style={{ color: "#069843" }} className="">
          {ecomm_list_price}
        </span>
        <span className="text-gray-400">/{ecomm_unit}</span>
      </p>
    </div>
  );
};

export default ProductCard;
