/* eslint-disable no-unused-vars */
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { baseURL } from "../constants/api";
import { useContext } from "react";
import { getHeaders } from "./helper";
import { AppContext } from "../constants/AppContext";

export const getProfileData = async (props) => {
  const config = getHeaders();
  const { data } = await axios.post(
    baseURL + "auth/checkRegistration",
    props,
    config
  );
  return data;
};

export default function useProfile(onLogin = false) {
  // const queryClient = useQueryClient();
  const { user, idToken } = useContext(AppContext);
  const props = {
    customer_mobile: user?.phoneNumber,
    fb_auth_uuid: user?.uid || "",
    fb_auth_token: idToken,
  };

  return useQuery(["profile", props], () => getProfileData(props), {
    onSuccess: (_data) => {
      // queryClient.invalidateQueries();
      // queryCache.invalidateQueries('')
      // queryClient.invalidateQueries("cartItems");
      // queryCache.invalidateQueries('favorites')
    },
  });
}
