import React from "react";

function ContactEmailIcon() {
  return (
    <div>
      {" "}
      <svg
        width="26"
        height="17"
        viewBox="0 0 26 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9823 0H3.05871C1.37221 0 0 1.37221 0 3.05871V13.9413C0 15.6278 1.37221 17 3.05871 17H21.9823C23.6688 17 25.041 15.6278 25.041 13.9413V3.05904C25.0414 1.37221 23.6692 0 21.9823 0ZM20.4877 1.99546L12.5205 7.12845L4.55331 1.99546H20.4877ZM21.9823 15.0049H3.05871C2.47238 15.0049 1.99546 14.5276 1.99546 13.9416V3.11558L11.9049 9.48775C11.9182 9.49607 11.9325 9.50172 11.9462 9.50937C11.9605 9.51735 11.9751 9.525 11.9897 9.53232C12.0666 9.57189 12.1457 9.60382 12.2265 9.62477C12.2348 9.6271 12.2432 9.6281 12.2515 9.6301C12.3403 9.65105 12.4301 9.66402 12.5199 9.66402C12.5202 9.66402 12.5205 9.66402 12.5205 9.66402C12.5212 9.66402 12.5215 9.66402 12.5219 9.66402C12.6116 9.66402 12.7014 9.65138 12.7902 9.6301C12.7986 9.6281 12.8069 9.6271 12.8152 9.62477C12.896 9.60382 12.9748 9.57189 13.052 9.53232C13.0666 9.525 13.0812 9.51735 13.0955 9.50937C13.1092 9.50172 13.1235 9.49607 13.1368 9.48775L23.0462 3.11558V13.9413C23.0459 14.5276 22.5687 15.0049 21.9823 15.0049Z"
          fill="#CB8A0A"
        />
      </svg>
    </div>
  );
}

export default ContactEmailIcon;
